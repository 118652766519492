import router from './router'

import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'// progress bar style

import {Message} from 'element-ui'



router.beforeEach((to,from,next) =>{
  let token = localStorage.getItem('token');//获取token
if (token || to.path === '/login' || to.path === '/noSessionBeginTask') {//有token或者在login页面下通行
  NProgress.start() // start progress bar
  next();
} else {
  // alert('无权访问');
       Message({
    message:(!localStorage.getItem('locale')) || (localStorage.getItem('locale') == 'zh') ? '请登录':'Please login',
        type:'error'
  })
  next('/login');
}
})
router.afterEach(() => {
NProgress.done() // finish progress bar
})
